@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("ui/fonts/PlusJakartaSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("ui/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf");
  font-style: italic;
}

@layer base {
  html {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
  }
}

input.defaultCheckbox::before {
  content: url(ui/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.chakra-popover__popper {
  z-index: 1000;
}

.swiper-slide {
  height: auto !important;
}

.popover-open {
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
}

.popover-close {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.filters-open {
  margin-left: -100vw;
  transition: all 0.3s linear;
}

.filters-closed {
  margin-left: 0;
  transition: all 0.3s linear;
}

.sidebar-open {
  margin-left: 0;
  transition: all 0.3s linear;
}

.sidebar-closed {
  margin-left: -80vw;
  transition: all 0.3s linear;
}

.activity-banner svg {
  border-radius: 20px;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        theme("colors.secondaryGrey.900") 94%,
        #0000
      )
      top/8px 8px no-repeat,
    conic-gradient(#0000 30%, theme("colors.secondaryGrey.900"));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

.premium-locked {
  opacity: 30%;
}

.textarea-scroll::-webkit-scrollbar {
  display: none;
}

.textarea-scroll {
  resize: none;
}

.react-time-picker__inputGroup {
  border: 1px solid theme("colors.darkGrey.500");
  width: fit-content !important;
  padding: 5px 10px;
  border-radius: 10px;
}

.duration-error .react-time-picker__inputGroup {
  border: 1px solid theme("colors.error");
}

.react-time-picker__inputGroup__divider {
  margin-left: 5px;
  margin-right: 5px;
}
